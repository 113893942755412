import React from 'react';
import { BrowserRouter as Router, Routes, Route} from "react-router-dom"
import Home from './components/home/Home';
import HomeApp from './codakraft/components/App';

const App = () => {
  return (
    <Router>
    <Routes>
      <Route path='/' element={<HomeApp />}/>
    </Routes>
   </Router>
  )
}


export default App
