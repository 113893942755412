/**
 * This code was generated by Builder.io.
 */
import React from "react";
import Navigation from "./Navigation";

const Header = () => {
  return (
    <header className="header">
      <div className="logo-container">
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/20d55bc35f2e7dbe651f79b1ca4c73dc238f2da280ae5bad2eb1f28c2a6f418b?placeholderIfAbsent=true&apiKey=0dc91187d1fc4cb3931d3d7b27a4a892"
          alt="Agency Logo"
          className="logo-image"
        />
        <span className="logo-text">CodaKraft</span>
      </div>
    </header>
  );
};

export default Header;
