/**
 * This code was generated by Builder.io.
 */
import React from "react";
import Header from "./Header";
import "./hero.css";
import AnchorLink from "react-anchor-link-smooth-scroll";

const HeroSection = () => {
  return (
    <section className="hero-section">
      <div className="hero-content">
        <Header />
        <div className="hero-main">
          <div className="hero-content-wrapper">
            <div className="hero-text">
              <div className="hero-text-content">
                <div className="hero-subtitle">Dev Studio</div>
                <h1 className="hero-title">
                  We Help To Build Your Dream Project
                </h1>
                <p className="hero-description">
                  CodaKraft provides a full service range including technical
                  skills, design, business understanding.
                </p>
                <div className="hero-cta">
                  <AnchorLink href="#contact-form">
                    <button className="primary-button">
                      <div className="primary-button-text">Contact Us</div>
                    </button>
                  </AnchorLink>
                  {/* <button className="secondary-button">Contact Us</button>*/}
                </div>
                <div className="hero-quote">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/385286e955d859ff90458b0951ca66b7976db37443f6b1dcd430b6386cb7c39a?placeholderIfAbsent=true&apiKey=0dc91187d1fc4cb3931d3d7b27a4a892"
                    className="quote-icon"
                    alt=""
                  />
                  <div className="quote-text">
                    "Put themselves in the merchant's shoes"
                  </div>
                </div>
              </div>
            </div>
            <div className="hero-image">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e554a3365a6d5cb17779d197b2f7ee46c14ebf99f91e069d5a2eeb9405c64624?placeholderIfAbsent=true&apiKey=0dc91187d1fc4cb3931d3d7b27a4a892"
                className="hero-image-content"
                alt="Hero illustration"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
