/**
 * This code was generated by Builder.io.
 */
import React from "react";

const Team = () => {
  const teamMembers = [
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/afgog-1a208.appspot.com/o/property%2Fsaba%20ayo.jpeg?alt=media&token=8e341252-f618-4334-8648-78443e479188",
      name: "Ayodeji Saba",
      role: "",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/afgog-1a208.appspot.com/o/property%2FAdedamola.jpeg?alt=media&token=29a2fbab-7e00-4588-b1e3-d2ce209e860a",
      name: "Adedamola Agunbiade",
      role: "",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/afgog-1a208.appspot.com/o/property%2Fvalentina.jpeg?alt=media&token=a62ade77-47af-408d-b169-8e3bad9ce09e",
      name: "Valentina Atewogbade",
      role: "",
    },
    // {
    //   image:
    //     "https://cdn.builder.io/api/v1/image/assets/TEMP/3e47f7bf418d764083948da1cb548ab773090bbc18d6d7d78d6cb5092c2929fc?placeholderIfAbsent=true&apiKey=0dc91187d1fc4cb3931d3d7b27a4a892",
    //   name: "Izabella Tabakova",
    //   role: "Product Designer",
    // },
  ];

  return (
    <section className="team">
      <h2 className="section-title">Our Team</h2>
      <h3 className="team-heading">The CodaKraft Team</h3>
      <div className="team-grid">
        {teamMembers.map((member, index) => (
          <div key={index} className="team-member">
            <img
              src={member.image}
              alt={member.name}
              className="member-image"
            />
            <h4 className="member-name">{member.name}</h4>
            <p className="member-role">{member.role}</p>
          </div>
        ))}
      </div>
      <div className="team-navigation">
        <span className="nav-dot active"></span>
        <span className="nav-dot"></span>
        <span className="nav-dot"></span>
      </div>
    </section>
  );
};

export default Team;
