/**
 * This code was generated by Builder.io.
 */
import React from "react";
import mary from '../../assets/image/Mary.jpg'
import dennis from '../../assets/image/dennis.jpg'
import tunde from '../../assets/image/tunde.jpg'

const Testimonials = () => {
  const testimonials = [
    {
      content:
        "Codakraft is an team of people. They are highly skilled, professional, and consistently exceeds expectations. If you need innovative, user-friendly digital solutions, Codakraft is the perfect choice!",
      author: "Mary Williams",
      company: "Devtage",
      image: mary,
    },
    {
      content:
        "Their expertise, innovation, and dedication to quality make them a trusted partner for any digital project. Highly recommended!",
      author: "Tunde Obikoya",
      company: "Vision Techers",
      image: tunde,
    },
    {
      content:
        "The team's expertise and commitment to delivering innovative, user-friendly products make Codakraft a trusted partner for any digital project.",
      author: "Dennis John",
      company: "Vision Techers",
      image: dennis,
    },
  ];

  return (
    <section className="testimonials">
      <h2 className="section-title">Testimonials</h2>
      <h3 className="testimonials-heading">What Our Clients Saying</h3>
      <div className="testimonials-grid">
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-card">
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/4827f4635ca009d161e109090b6d3122297123ba68c64a810b1497eeff0ef764?placeholderIfAbsent=true&apiKey=0dc91187d1fc4cb3931d3d7b27a4a892"
              alt="Quotation mark"
              className="quote-icon"
            />
            <p className="testimonial-content">{testimonial.content}</p>
            <div className="testimonial-author">
              <img
                src={testimonial.image}
                alt={testimonial.author}
                className="author-image"
              />
              <div className="author-info">
                <h4 className="author-name">{testimonial.author}</h4>
                <p className="author-company">{testimonial.company}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <button className="testimonials-cta">See All</button>
    </section>
  );
};

export default Testimonials;
