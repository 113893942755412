/**
 * This code was generated by Builder.io.
 */
import React from "react";

const Footer = () => {
  const menuItems = ["About", "Services", "Blog", "Contact"];
  const serviceItems = ["Design", "Development", "Marketing", "See More"];

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <h2 className="logo-text">CodaKraft</h2>
        </div>
        {/* <div className="footer-menu">
          <h3 className="footer-heading">Menu</h3>
          <ul className="footer-list">
            {menuItems.map((item, index) => (
              <li key={index}>
                <a href={`#${item.toLowerCase()}`}>{item}</a>
              </li>
            ))}
          </ul>
        </div>
        <div className="footer-service">
          <h3 className="footer-heading">Service</h3>
          <ul className="footer-list">
            {serviceItems.map((item, index) => (
              <li key={index}>
                <a href="#">{item}</a>
              </li>
            ))}
          </ul>
        </div> 
        <div className="footer-social">
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/5686e49c28d16aa3a24d74666ce94bca0fa8739ce3d81c377bfd73de91296687?placeholderIfAbsent=true&apiKey=0dc91187d1fc4cb3931d3d7b27a4a892"
            alt="Social media icons"
            className="social-icons"
          />
        </div>*/}
      </div>
      <div className="footer-bottom">
        <p className="copyright">
          Copyright © 2024 CodaKraft. All Rights Reserved.
        </p>
        {/* <div className="legal-links">
          <a href="#" className="legal-link">
            Terms of Use
          </a>
          <a href="#" className="legal-link">
            Privacy Policy
          </a>
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;
