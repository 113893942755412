/**
 * This code was generated by Builder.io.
 */
import React from "react";
import orbit from "../../assets/image/orbits.jpeg";
import devtage from "../../assets/image/devtage.jpeg";
import visiontecher from "../../assets/image/visiontechers.jpeg";
import pendo from '../../assets/image/pendo.jpeg';

const Portfolio = () => {
  const categories = [
    { name: "Show All", count: 14 },
    { name: "Design", count: 6 },
    { name: "Branding", count: 4 },
    { name: "Illustration", count: 3 },
    { name: "Motion", count: 1 },
  ];

  const projects = [
    {
      image: devtage,
      category: "Development",
      title: "Devtage Financial service",
      link: "https://devtagefs.com",
    },
    {
      image: orbit,
      category: "Development",
      title: "Orbit Financial service",
      link: "https://devtagefs.com",
    },
    {
      image: visiontecher,
      category: "Development",
      title: "Vision Techers",
      link: "https://visiontechers.com/"
    },
    {
      image: pendo,
      category: "Development",
      title: "Pendo Design",
      link: "https://pendodesign.co/"
    },
  ];

  return (
    <section className="portfolio">
      <h2 className="section-title">Portfolio</h2>
      <h3 className="portfolio-heading">Latest Work</h3>
      {/*<div className="portfolio-categories">
        {categories.map((category, index) => (
          <button key={index} className="category-button">
            {category.name}{" "}
            <span className="category-count">{category.count}</span>
          </button>
        ))}
      </div> */}
      <div className="portfolio-grid">
        {projects.map((project, index) => (
          <div key={index} className="portfolio-item">
            <img
              src={project.image}
              alt={project.title}
              className="portfolio-image"
            />
            <div className="portfolio-overlay">
              <span className="portfolio-category">{project.category}</span>
              <a href={project.link} target="_blank" className="portfolio-link">
                <h4 className="portfolio-title">{project.title}</h4>
              </a>
            </div>
          </div>
        ))}
      </div>
      <button className="portfolio-cta">Explore More</button>
    </section>
  );
};

export default Portfolio;
