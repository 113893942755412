import React from 'react';

const ContactForm = () => (
  <section className="contact-section" id='contact-form'>
    <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/43960f5fd3686ac907041bc9c0563ec5e25e4bbdb259b29ee8219c5c7671a428?placeholderIfAbsent=true&apiKey=0dc91187d1fc4cb3931d3d7b27a4a892" alt="Contact decoration" className="contact-decoration" />
    <div className="contact-content">
      <form className="contact-form">
        <h2 className="form-title">Get In Touch</h2>
        <input type="email" placeholder="Your email" aria-label="Your email" className="form-input" required />
        <div className="form-select-wrapper">
          <select aria-label="Subject" className="form-select" required>
            <option value="" disabled selected>Subject</option>
            <option value="general">General Inquiry</option>
            <option value="support">Support</option>
            <option value="feedback">Feedback</option>
          </select>
        </div>
        <textarea placeholder="Message" aria-label="Message" className="form-textarea" required></textarea>
        <button type="submit" className="form-submit">Submit Now</button>
      </form>
      <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/f229a1d07d62320c6f991a3cd624af6e970d5cb039caa68da300692f9e2db00c?placeholderIfAbsent=true&apiKey=0dc91187d1fc4cb3931d3d7b27a4a892" alt="Contact illustration" className="contact-illustration" />
    </div>
  </section>
);

export default ContactForm;