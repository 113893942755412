/**
 * This code was generated by Builder.io.
 */
import React from "react";
import './styles.css'
import aboutImage from '../../assets/image/about.png'

const About = () => {
  return (
    <section className="about" id="about">
      <div className="about-image">
        <img
          src={aboutImage}
          alt="About us illustration"
          className="about-illustration"
        />
      </div>
      <div className="about-content">
        <h2 className="section-title">About</h2>
        <h3 className="about-heading">An Experienced Development Agency</h3>
        <p className="about-description">Provides a full service range</p>
        <p className="about-detail">
          Ability to put themselves in the merchant's shoes. It is meant to
          partner on the long run, and work as an extension of the merchant's
          team.
        </p>
        <button className="about-cta">Learn More</button>
      </div>
    </section>
  );
};

export default About;
