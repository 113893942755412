/**
 * This code was generated by Builder.io.
 */
import React from "react";
import Header from "./Header";
import Hero from "./Hero";
import About from "./About";
import Stats from "./Stats";
import Process from "./Process";
import VideoSection from "./VideoSection";
import Features from "./Features";
import Services from "./Services";
import Portfolio from "./Portfolio";
import Team from "./Team";
import Testimonials from "./Testimonials";
import Contact from "./Contact";
import Footer from "./Footer";
import './styles.css'
import HeroSection from "./HeroSection";
import ContactForm from "./ContactForm";

const HomeApp = () => {
  return (
    <div className="app">
      <main>
        <HeroSection />
        <About />
        {/* <Stats /> */}
        {/* <Process /> 
        <VideoSection />
        <Features /> */}
        <Services />
        <Portfolio />
        <Team />
        <Testimonials />
        <ContactForm />
        <Contact />
      </main>
      <Footer />
    </div>
  );
};

export default HomeApp;
