/**
 * This code was generated by Builder.io.
 */
import React from "react";

const Services = () => {
  const serviceCards = [
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/b27a310dbea77e96d39875ebcd9beb5902776073010d22444dc7e08c28b2f3eb?placeholderIfAbsent=true&apiKey=0dc91187d1fc4cb3931d3d7b27a4a892",
      title: "Design",
      description:
        "Agency provides a full service range including technical skills, design.",
      cta: "Learn More",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/6f94f7ee68089a8341eff6e0b42fbf7571a14c34f4fe5d03f8eccddb3d2b8a5a?placeholderIfAbsent=true&apiKey=0dc91187d1fc4cb3931d3d7b27a4a892",
      title: "Development",
      description:
        "Full service range including technical skills, design, business.",
      cta: "Discover More",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/1deda80f4df14b249268d1055fdd31f4e1111a14fa0e914ea8d7583373e9a635?placeholderIfAbsent=true&apiKey=0dc91187d1fc4cb3931d3d7b27a4a892",
      title: "Product Development",
      description: "Technical skills, design, business understanding, ability.",
      cta: "Explore Now",
    },
  ];

  return (
    <section className="services" id="services">
      <h2 className="section-title">Service</h2>
      <h3 className="services-heading">How CodaKraft Can Help</h3>
      <div className="service-cards">
        {serviceCards.map((card, index) => (
          <div key={index} className="service-card">
            <img
              src={card.icon}
              alt={`${card.title} icon`}
              className="service-icon"
            />
            <h4 className="service-title">{card.title}</h4>
            <p className="service-description">{card.description}</p>
            <a href="#" className="service-cta">
              {card.cta}
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;
