/**
 * This code was generated by Builder.io.
 */
import React from "react";

const Contact = () => {
  const contactInfo = [
    // {
    //   icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/90f3aaf71dc2ba5eb172e6300dbcdbbe38882cf6d3a7561ebdfd572daf28591c?placeholderIfAbsent=true&apiKey=0dc91187d1fc4cb3931d3d7b27a4a892",
    //   title: "Phone",
    //   content: "+1 (234) 567-89-00",
    // },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/ea85f1a548674927c4a2bb9832c3b73c5d9f2b4e59e54cf4ac50845bbb561301?placeholderIfAbsent=true&apiKey=0dc91187d1fc4cb3931d3d7b27a4a892",
      title: "EMail",
      content: "hello@codakraft.com",
    },
    // {
    //   icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/8202d6fa7329d6c9b62ff844392651d2cca431bbc2e5beb583dd04ffcb1938d5?placeholderIfAbsent=true&apiKey=0dc91187d1fc4cb3931d3d7b27a4a892",
    //   title: "Address",
    //   content: "2247 Lunetta Street, TX 76301",
    // },
  ];

  return (
    <section className="contact" id="contact">
      <div className="contact-info">
        {contactInfo.map((item, index) => (
          <div key={index} className="info-item">
            <img src={item.icon} alt={item.title} className="info-icon" />
            <h4 className="info-title">{item.title}</h4>
            <p className="info-content">{item.content}</p>
          </div>
        ))}
      </div>
      {/* <div className="contact-form-container">
        <div className="form-content">
          <h2 className="form-title">We're Help To Build Your Dream Project</h2>
          <p className="form-description">
            A digital agency is a business you hire to outsource your digital
            marketing efforts, instead of handling in-house.
          </p>
          <button className="contact-cta">Contact Us</button>
        </div>
        <div className="form-image">
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/5c29286df815ad035c1442c13a904a50aa3183c1e65fd2869dd57065c698ceb5?placeholderIfAbsent=true&apiKey=0dc91187d1fc4cb3931d3d7b27a4a892"
            alt="Contact illustration"
            className="contact-illustration"
          />
        </div>
      </div> */}
    </section>
  );
};

export default Contact;
