import React, { useState } from "react";
import logo from "../images/logo.png";
import "./navbar.css";
// import { Outlet } from 'react-router-dom';
function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <section>
      <div className="navbar">
        <div className="logo">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/20d55bc35f2e7dbe651f79b1ca4c73dc238f2da280ae5bad2eb1f28c2a6f418b?placeholderIfAbsent=true&apiKey=0dc91187d1fc4cb3931d3d7b27a4a892"
            className="logo-image"
            alt="Agency logo"
          />
          <div className="logo-text">CodaKraft</div>
        </div>

        <div className={`nav-items ${isOpen && "open"}`}>
          <a href="About us">About</a>
          <a href="services">Services</a>
          <a href="faqs">pricing</a>
          <a href="blog">Blog</a>
        </div>

        <div className="nav-foot">
          <button className="btn-1">Contact</button>
        </div>

        <div
          className={`nav-toggle ${isOpen && "open"}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="bar"></div>
        </div>
      </div>

      {/* <Outlet/> */}
    </section>
  );
}

export default Navbar;
